import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { getConfigOpenAI } from "../../services/openAI/getConfigOpenAI";
import { AuthContext } from "../../contexts/auth";
import { postDalle } from "../../services/openAI/dalle";
import { getOpenAI } from "../../services/openAI/getOpenAi";
import { postHistoric } from "../../services/postHistoric";
import formatDate from "../../utils/formatDate";
import { postImagesSDXL } from "../../services/monsterAI/postImagesSDXL";

const useDalleState = () => {
    const { signOut, upgradePanel, user } = useContext(AuthContext);
    
    const [sendText, setSendText] = useState("");
    const [style, setStyle] = useState("");
    const [size, setSize] = useState(512);
    const [returnImages, setReturnImages] = useState([]);
    const [submit, setSubmit] = useState(false);

    

    const handleAPI = async (updateHistoric) => {
        if (submit) {
            return;
        }

        let isSendTextValid = false;

        if (user.images_used >= user.images){
            let upg = {...upgradePanel.value}
            upg.title = 'Hey there!'
            upg.text = "Looks like you've used up your free image credits for this month. But don't let that stop your creativity! You can either upgrade to a PRO plan or purchase an image pack starting from just €3,50 (no subscription required). Keep on creating amazing art with us!"
            upg.textButton = 'Upgrade your Plan or Buy image credits now!'
            upg.show = true
            upg.view = true
            upg.navegate = '/credits'
            upgradePanel.set(upg)
        }
        else if (sendText.length > 4000) {
            toast.error(
                <FormattedMessage id="More than 4000 characters typed" />
            );
        } 
        else if (sendText !== "") {
            isSendTextValid = true;
        }
        else {
            toast.error(<FormattedMessage id="Provide a valid text" />);
        }

        if (isSendTextValid) {
            setSubmit(true);
            let config = await getConfigOpenAI(sendText.trim(), 5, setSubmit, signOut)

            if (!config){
                setSubmit(false);
                return ''
            }
            config = {
                ...config,
                model: 'gpt-4o-mini',
                
            }
            let messages = [
                {'role': 'system', "content": "You are a professional English translator."},
                {'role':'user', "content": `Translate the following text into English. If the text is already in English, just write it as it was written: ${sendText.trim()}`}
            ]
            let send = JSON.stringify({text: sendText.trim()});
            let chat = await getOpenAI(messages, config)
            

            await postHistoric({
                send: send,
                text: JSON.stringify(messages),
                result: chat,
                date: formatDate(new Date()),
                action: 5
            })

            let json = {
                text: chat.trim(),
                size: size,
                style: style,
                date: formatDate(new Date()),
            };

            if (style === 'Hyper realistic'){
                
                json.style = 'photographic'
                return await postImagesSDXL(json)
                .then((json) => {
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {
                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else if (json.data.code === "no images") {
                            toast.error('Error: Something went wrong', {
                                message:<>
                                            <FormattedMessage id="withoutImg"></FormattedMessage>
                                            <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                        </>,
                            });
                            setReturnImages([]);
                        } else {
                            toast.error(
                                <FormattedMessage id="unknown error" />
                            );
                            setReturnImages([]);
                        }
                    } else {
                        let imgs = JSON.parse(json.data);
                        setReturnImages(imgs.urls);
                        updateHistoric.set();
                    }
                    setSubmit(false);
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }

                    setSubmit(false);
                });
            }
            else {
                return await postDalle(json)
                .then((json) => {
                    if (json?.type === "error") {
                        if (json.data.code === "token_not_valid") {
                            signOut();
                        } else if (
                            json.data.code === "violation of guidelines"
                        ) {
                            toast.error(
                                <FormattedMessage id={json.data.detail} />
                            );
                        } else if (json.data.code === "no images") {
                            toast.error('Error: Something went wrong', {
                                message:<>
                                            <FormattedMessage id="withoutImg"></FormattedMessage>
                                            <FormattedMessage id="upgrade_to_use"></FormattedMessage>
                                        </>,
                            });
                            setReturnImages([]);
                        } else {
                            toast.error(
                                <FormattedMessage id="unknown error" />
                            );
                            setReturnImages([]);
                        }
                    } else {
                        let imgs = JSON.parse(json.data);
                        setReturnImages(imgs.urls);
                        updateHistoric.set();
                    }
                    setSubmit(false);
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(
                            <FormattedMessage id="Connection failed to server" />
                        );
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }

                    setSubmit(false);
                });
            }


        }
    };

    return {
        sendText: {
            value: sendText,
            set: setSendText,
        },
        size: {
            value: size,
            set: setSize,
        },
        style: {
            value: style,
            set: setStyle,
        },
        returnImages: {
            value: returnImages,
            set: setReturnImages,
        },
        submit: {
            value: submit,
            set: handleAPI,
        },
    };
};

export default useDalleState;
