import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider 
} from "@mui/material";
import { Mic as MicIcon, MicOff as MicOffIcon } from "@mui/icons-material";
import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';

// Speech recognition and translation provided by Microsoft Cognitive Services
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

const WEBSOCKET_URL = 'wss://app.admind.ai/ws/talktranslate/';
//const WEBSOCKET_URL = "ws://192.168.0.17:8000/ws/talktranslate/";
// 192.168.0.17 - Mari
// 192.168.1.5 - Lário
const LANGUAGE_OPTIONS = ["english", "spanish", "portuguese", "italian"]; 
const LANGUAGE_OUTPUT = ["en", "es", "pt", "it"];
const LANGUAGE_INPUT = ["en-US", "es-ES", "pt-BR", "it-IT"];

// Azure Speech Key and Region
const SPEECH_KEY = process.env.REACT_APP_SPEECH_KEY;
const SPEECH_REGION = process.env.REACT_APP_SPEECH_REGION;

const MySelect = ({ label, id, value, onChange, size, sx, options, disabled }) => (
  <FormControl fullWidth>
    <InputLabel id={`labelId${label}`}>{label}</InputLabel>
    <Select
      id={id}
      labelId={`labelId${label}`}
      value={value}
      label={label}
      onChange={onChange}
      size={size}
      sx={sx}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const TalkArea = ({ user, messages }) => (
  <Box
    sx={{
      height: "50vh",
    }}
  >
    {[...messages].reverse().map((message, index) => {
      const isOwner = message.user === user; 
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: isOwner ? "flex-end" : "flex-start",
            mb: 1,
          }}
        >
          <Box
            sx={{
              maxWidth: "75%",
              backgroundColor: isOwner ? "#ebebeb" : "#d0b5d5",
              p: 2,
              borderRadius: 5,
              wordBreak: "break-word",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {message.user}
            </Typography>
            <Typography variant="body2">{message.translate}</Typography>
          </Box>
        </Box>
      );
    })}
  </Box>
);

const AisolutionsTalkTranslator = () => {
  // Estados relacionados à funcionalidade de escuta e fala
  const [isListening, setIsListening] = useState(false);
  const [talking, setTalking] = useState('');

  // Estados relacionados ao usuário e suas preferências
  const [user, setUser] = useState("");
  const [languageInput, setLanguageInput] = useState("");
  const [languageOutput, setLanguageOutput] = useState("");

  // Estados relacionados às mensagens e comunicação
  const [messages, setMessages] = useState([]);
  const recognizerRef = useRef(null);
  const socket = useRef(null);

  const startRecognition = () => {
    const speechConfig = SpeechSDK.SpeechTranslationConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );

    speechConfig.speechRecognitionLanguage = LANGUAGE_INPUT[LANGUAGE_OPTIONS.indexOf(languageInput)];
    // Alterar para só traduzir para os idiomas selecionados pelos usuários
    LANGUAGE_OUTPUT.forEach((lang) => speechConfig.addTargetLanguage(lang));

    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechSDK.TranslationRecognizer(speechConfig, audioConfig);
    console.log(languageInput,">",languageOutput," :", user);

    recognizer.recognizing = (s, e) => {
      const realTimeText = e.result.translations.get(LANGUAGE_OUTPUT[LANGUAGE_OPTIONS.indexOf(languageOutput)]);
      if (realTimeText) {
        console.log("Texto reconhecido em tempo real:", realTimeText);
    
        // Criar objeto de traduções para todas as opções de idioma
        const translations = LANGUAGE_OPTIONS.reduce((acc, lang, index) => {
          const translatedText = e.result.translations.get(LANGUAGE_OUTPUT[index]);
          if (translatedText) {
            acc[lang] = translatedText;
          }
          return acc;
        }, {});

        // Enviar para o WebSocket usando o formato esperado
        sendTranslationsSocket({
          type: "realTime",
          user,
          text: translations,
        });
      }
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === SpeechSDK.ResultReason.TranslatedSpeech) {
        // Criar objeto de traduções para todas as opções de idioma
        const translations = LANGUAGE_OPTIONS.reduce((acc, lang, index) => {
          const translatedText = e.result.translations.get(LANGUAGE_OUTPUT[index]);
          if (translatedText) {
            acc[lang] = translatedText;
          }
          return acc;
        }, {});
    
        console.log("Texto final reconhecido:", translations);
    
        // Enviar para o WebSocket usando o formato esperado
        sendTranslationsSocket({
          type: "final",
          user,
          text: translations,
        });
      }
    };

    recognizer.canceled = (s, e) => {
        console.error("Reconhecimento cancelado:", e);
        stopRecognition();
    };

    recognizer.sessionStopped = () => {
        stopRecognition();
    };

    recognizer.startContinuousRecognitionAsync();
    recognizerRef.current = recognizer;
    setIsListening(true);
  };

  const stopRecognition = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      recognizerRef.current = null;
    }
    setIsListening(false);
  };

  useEffect(() => {
    socket.current = new WebSocket(WEBSOCKET_URL);
    
    socket.current.onopen = () => {
        console.log("WebSocket conectado");
    };

    socket.current.onmessage = (response) => {
      const data = JSON.parse(response.data);
    
      if (data.messagetype === "realTime") {
        setTalking(data.user);
        setMessages((prevMessages) => {
          const existingIndex = prevMessages.findIndex(
            (msg) => msg.user === data.user && msg.isTemporary
          );
    
          if (existingIndex !== -1) {
            // Atualiza a mensagem existente
            const updatedMessages = [...prevMessages];
            updatedMessages[existingIndex] = {
              ...updatedMessages[existingIndex],
              translate: data.message,
            };
            return updatedMessages;
          } else {
            // Adiciona uma nova mensagem temporária
            return [
              ...prevMessages,
              {
                user: data.user,
                translate: data.message,
                isTemporary: true,
              },
            ];
          }
        });
      } else if (data.messagetype === "final") {
        setTalking("");
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.user === data.user && msg.isTemporary
              ? { ...msg, translate: data.message, isTemporary: false }
              : msg
          )
        );
      }
    };       

    // Log de erros
    socket.current.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
    };

    socket.current.onclose = (e) => {
        console.error("WebSocket fechado:", e);
    };

    return () => {
        if (socket.current) {
            socket.current.close();
        }
    };
  }, []);

  // Função genérica para enviar mensagens ao WebSocket
  const sendMessage = (message) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify(message);
      console.log("Enviando mensagem:", jsonMessage);
      socket.current.send(jsonMessage);
    } else {
      console.warn("WebSocket não está conectado.");
    }
  };

  // Enviar ação para definir o idioma
  const setLanguageSocket = useCallback((input_language, output_language) => {
    sendMessage({
      action: "set_language",
      input_language: input_language,
      output_language: output_language
    });
  }, []);

  // Enviar traduções para o backend
  const sendTranslationsSocket = (translations) => {
    sendMessage({
      action: "send_translations",
      type: translations.type,
      user: translations.user,
      translations: translations.text,
    });
  };
  
  useEffect(() => {
    if (user && languageInput && languageOutput) {
        console.log(user, ": ", languageInput, ">", languageOutput);
        setLanguageSocket(languageInput, languageOutput);
    }
  }, [user, languageInput, languageOutput, setLanguageSocket]);
  
  return (
    <Container maxWidth="md">
      <br />
      <Paper style={{ padding: "1em", height:'90vh', overflow: 'hidden' }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          SyncSpeak
        </Typography>
        <Box display="flex" justifyContent="left" marginBottom={2} >
          <TextField
            sx={{ marginRight: '10px' , width: '100%' }}
            placeholder={"write your name for the speech button to activate"}
            label="User"
            variant="outlined"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
            disabled={isListening}
          />
          <Button
            sx={{ borderRadius: '30px' }}
            disabled={!user || !languageInput || !languageOutput}
            variant="contained"
            color={isListening ? "secondary" : "primary"}
            onClick={() => (isListening ? stopRecognition() : startRecognition())}
          >
            {isListening ? <MicOffIcon /> : <MicIcon />}
          </Button>
        </Box>
        <Box>
        </Box>
        <Box sx={{height:'20px'}}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <MySelect
            id="language-in"
            label="Input language"
            options={LANGUAGE_OPTIONS}
            value={languageInput}
            onChange={(e) => {
              setLanguageInput(e.target.value);
            }}
            disabled={isListening}
          />
          <Box sx={{width:'20px'}}/>
          <MySelect
            id="language-out"
            label="Translate language"
            options={LANGUAGE_OPTIONS}
            value={languageOutput}
            onChange={(e) => {
              setLanguageOutput(e.target.value);
            }}
            // disabled={isListening}
        />
        </Box>
        <Box display="flex" justifyContent="center" marginBottom={0} height="40px">
          <Typography 
            variant="h6" 
            component="h1" 
            align="center" 
            gutterBottom 
            style={{ visibility: talking ? 'visible' : 'hidden' }}
          >
            {talking} is speaking...
          </Typography>
        </Box>
        {messages.length > 0 && (
          <Box
            sx={{
              padding: "10px",
              maxHeight: "500px",
              overflowY: "auto",
              ...scrollbarTheme,
            }}
          >
            <TalkArea user={user} messages={messages} />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default AisolutionsTalkTranslator;