import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MyLabel from "../../components/myLabel";
import MyButton from "../../components/myButton";
import MyInputText from "../../components/myInputText";
import daleImage from "../../assets/imgs/dale.png";
import CHANNELS from "../../components/ayrshare/channelsNew"
import { scrollbarTheme } from '../../assets/customThemes/scrollbarTheme';
import { Card, CardContent, Dialog, DialogContent, DialogTitle } from "@mui/material";
import MyTimeInput from "../../components/myTimeInput";
import CustonSelect from "../../components/ayrshare/posts/custonSelect";
import moment from "moment";
import DragDropImage from "../../components/dragDropImage";
import MyDataTimeInput from "../../components/myDataTimeInput";
import useAIAdFromProductDescriptionState from "../../hooks/ayrshare/useAIAdFromProductDescriptionState";
import MyImage from "../../components/myImage";
import ButtonDropImage from "../../components/ayrshare/viewTemplate/buttonDropImage";
import PreviewIcon from '@mui/icons-material/Preview';
import MySelect from "../../components/mySelect";

const languageMapped = {
    0: 'english',
    1: 'spanish',
    2: 'portuguese',
    3: 'italian',
    4: 'german',
    5: 'french'
}

const style ={
    container:{
        minWidth: '700px',
        maxHeight:'80%',
        height:'80%',
        overflowY: "auto", 
        '@media (max-width: 600px)': {
            width: 'auto',
            minWidth: 'auto',
            maxHeight:'100vh',
        },
        ...scrollbarTheme
    },
    title:{
        fontSize: '12pt',
        fontWeight: '900'
    },
    descriptions:{
        fontSize: '12pt',
    },
    selectArea: {
        minWidth: '150px',
        '@media (max-width: 600px)': {
            width: 'auto',
        }
    },
    centerArea:{
        overflowY:'auto',
        padding: '0px 40px',
        '@media (max-width: 600px)': {
            padding: '0px 10px',
        }
    },
    blueButton: {
        backgroundColor: '#376db4',
        ':hover': {
            backgroundColor: '#598FD6',
        }
    },
    publicationsArea: {
        maxHeight:'280px', 
        overflowY: "auto", 
        marginBottom:'10px',
        ...scrollbarTheme,
    },
    selectRowArea: {
        display: 'grid',
        gap: '15px',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center', 
        width: '100%', 
        margin:'15px 0',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',

        }
    },
    cardAreaImage:{
        width: '130px',
        height:'130px',
        display:'flex',
        
        backgroundImage: `url(${daleImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 600px)': {
            width: '180px',
            height: '180px',
          }
    },
    cardAreaContent:{
        display:'flex',
        width: '100%',
        height:'100%',
        margin: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    cardTitleArea: {
        width: '100%',
        display:'flex',
        backgroundColor: '#227efd',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        alignCenter: 'center',
    },
    cardTitle: { 
        fontSize: '9pt',
        fontWeight: 700,
        color: '#FFF',
        textAlign: 'center',
    },
}

function MessageDialog({open, handleClose, dateTime, setDateTime, handlerSchedulePublish}) {
  
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={"Schedule post"}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:'30px'}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                <MyTimeInput value={dateTime} onChange={(newValue) => setDateTime(newValue)} />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.scheduleButton}
                        onClick={handleClose}
                        text='cancel'
                        variant="contained" 
                        size="small"
                    />
                    <MyButton 
                        sx={{...style.blueButton, marginBottom:0}}
                        onClick={handlerSchedulePublish}
                        text='Schedule'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
}

const MessageDialogImage = ({open, handleClose, image}) => {
    return (
        <Dialog  open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <MyLabel component='p' text={" "}  />
        </DialogTitle>
        <DialogContent>
          <Box sx={{margin:0}}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:0}}>
                <MyImage src={image} alt={`Image`}  sx={{width:'500px'}} />
            </Box>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%', margin:'10px'}}>
                    <MyButton 
                        sx={style.scheduleButton}
                        onClick={handleClose}
                        text='return'
                        variant="contained" 
                        size="small"
                    />
                </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )

}

const AdPostModal = ({setClose, profileSelected, socials, daysSelected, profile}) =>{
    const isMobile = window.innerWidth < 600
    const action = useAIAdFromProductDescriptionState(setClose, profile);
    
    let activeChannels = CHANNELS.filter(channel => channel.active && socials.value?.includes(channel.name))

    const [postFacebookPage, setPostFacebookPage] = useState(socials.value && socials.value?.includes('facebook') && socials.value?.includes('facebook') && activeChannels.includes('facebook'))
    const [postFacebookGroup, setPostFacebookGroup] = useState(socials.value && socials.value?.includes('fbg') && activeChannels.includes('fbg'))
    const [postInstagram, setPostInstagram] = useState(socials.value && socials.value?.includes('instagram') && activeChannels.includes('instagram'))
    const [postTwitter, setPostTwitter] = useState(socials.value && socials.value?.includes('twitter') && activeChannels.includes('twitter'))
    const [postLinkedin, setPostLinkedin] = useState(socials.value && socials.value?.includes('linkedin') && activeChannels.includes('linkedin'))
    const [postTiktok, setPostTiktok] = useState(socials.value && socials.value?.includes('tiktok') && activeChannels.includes('tiktok'))
    const [postYoutube, setPostYoutube] = useState(socials.value && socials.value?.includes('youtube') && activeChannels.includes('youtube'))

    const [inputText, setInputText] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const shouldEnableButton = profileSelected || inputText.trim().length > 0;
        setIsButtonDisabled(!shouldEnableButton);
    }, [profileSelected, inputText]);

    const handleInputChange = (e) => {
        setInputText(e.target.value);
        if (action.sendText?.set) {
            action.sendText.set(e.target.value);
        }
    };


    useEffect(() => {
        action.profileSelected.set(profileSelected)
    }, []);


    const handlerSchedulePublish = async () => {
        let hour = `${action.dateTime.value.hour()}:${action.dateTime.value.minute()}`;

        let post = {
            facebook: postFacebookPage ? [daysSelected] : [],
            fbg: postFacebookGroup ? [daysSelected] : [],
            instagram: postInstagram ? [daysSelected] : [],
            twitter: postTwitter ? [daysSelected] : [],
            linkedin: postLinkedin ? [daysSelected] : [],
        }

        action.open.set(false)
        action.submit.set(post, hour)
    }

    
    const [yearAndMonth, setYearAndMonth] = useState([moment().year(), moment().month() + 1]);

    const handleMonthSelect = (evt) => {
        let nextYear = yearAndMonth[0];
        let nextMonth = parseInt(evt.target.value, 10);
        setYearAndMonth([nextYear, nextMonth]);
    };

    const handleYearSelect = (evt) => {
        let nextMonth = yearAndMonth[1];
        let nextYear = parseInt(evt.target.value, 10);
        setYearAndMonth([nextYear, nextMonth]);
    };
    
    return (
        <Box sx={style.container}>

            <Box sx={style.centerArea}>
            {isMobile &&
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px', }}>
                {
                    action.isGenerateModal.value || action.imageAutoGenerate.value || action.submit.value ?
                        <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems: 'center'}}>
                            <CircularProgress size='2em' color="primary" />
                            <MyLabel noTranslate={true} sx={{color:'rgb(192, 114, 207)', fontSize:'10pt', marginTop:2, fontStyle:'italic'}} text={action.messageUser.value} />
                        </Box>
                    : 
                        <MyButton 
                            sx={{backgroundColor:'#1cd697'}}
                            onClick={action.submit.set}
                            disabled={action.isGenerateModal.value}
                            text='Save'
                            variant="contained" 
                            size="medium"
                        />
                }
                
            </Box>
            }
            <Box sx={{width:'100%', display: 'flex', justifyContent:'start', textAlign:'left', marginTop:isMobile ? '2rem' : '1rem'}} >
                <Box sx={{width: '200px', marginBottom:'1rem'}} >
                        
                    <Box sx={{display:'flex', flexDirection:'row', marginRight:'1rem'}}>
                        <CustonSelect
                            id='style'
                            size={'small'}
                            sx={{backgroundColor:'white'}}
                            options={activeChannels}
                            value={activeChannels.filter(item=>item.name === action.social.value)[0]?.id}
                            onChange={(e) => action.social.set(activeChannels.filter(item=>item.id === e.target.value)[0]?.name)}
                            noTranslate={true}
                        />  
                    </Box>    
                </Box>
                <MyDataTimeInput
                    value={action.dateTime.value}
                    onChange={(newValue) => {action.dateTime.set(newValue)}}
                    size='small'
                />
                <Box>
                    <MySelect
                        id='content'
                        size={'small'}     
                        sx={{backgroundColor:'white', width:'130px', marginLeft:"1rem"}}
                        options={['Languages', ...Object.values(languageMapped)]}
                        required={true}
                        value={action.language.value || ""}
                        onChange={(e)=>action.language.set(e.target.value)}
                    />
                </Box>
            </Box>
            <Box sx={{width:'100%', '@media (max-width: 600px)': {flexDirection:'column'}}} marginBottom={3}>
                <MyInputText
                    minRows={8}
                    type='text'
                    id="input-text"
                    label="Write your post text here or let the AI generate one for you..."
                    required={true}
                    value={action.sendText.value || ""}
                    onChange={handleInputChange}
                />
                <Box sx={{float:'right', marginTop:'0.5rem'}}>
                    <MyButton 
                        onClick={() => {
                            if (action.isGenerateModal?.set) {
                                action.isGenerateModal.set();
                            }
                        }}
                        disabled={isButtonDisabled}
                        text='Generate with AI'
                        variant="contained" 
                        size="medium"
                        style={{width:'220px', }}
                    />
                </Box>
                {isMobile && (
                    <Box sx={{marginTop:'3rem', position:"relative", left:'5%'}}>
                        <MyButton sx={{fontSize:'0.8rem', backgroundColor:"#f2f2f2", color:'black', width:'90%', padding:'0.8rem', marginTop:'1rem'}} text={"Genera un'altra immagine con AI"} />
                        <MyButton sx={{fontSize:'0.8rem', backgroundColor:"#f2f2f2", color:'black', width:'90%', padding:'0.8rem', marginTop:'1rem'}} text={"Carica un altra immagine"} />
                    </Box>
                )}
                {!isMobile && !action.file.value && (
                <Box sx={{position:'relative', left:'6rem', top:'2rem', display:'flex', flexDirection:'row', justifyContent:'space-between', border:'1px solid lightgrey', borderRadius:'12px', marginTop:'2rem', width:'70%'}}>
                    <Box sx={{padding:'1rem'}}>
                        <DragDropImage image={action.image} setFile={action.file.set} />
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center', paddingTop:'1.5rem', paddingRight:'1rem'}} >

                        <Card onClick={action.imageAutoGenerate.set} sx={style.cardAreaImage}>
                            <CardContent sx={style.cardAreaContent}>
                                <Box sx={style.cardTitleArea}>
                                    <MyLabel component='p' text="AUTO-GENERATE WITH AI" sx={style.cardTitle}  />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                )
                }
                

                </Box>
            
                {!isMobile &&
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px', marginTop:'3rem'}}>
                {
                    action.isGenerateModal.value || action.imageAutoGenerate.value || action.submit.value ? 
                        <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems: 'center'}}>
                            <CircularProgress size='2em' color="primary" />
                            <MyLabel noTranslate={true} sx={{color:'rgb(192, 114, 207)', fontSize:'10pt', marginTop:2, fontStyle:'italic'}} text={action.messageUser.value} />
                        </Box>: 
                        <MyButton 
                        sx={{backgroundColor:'#1cd697'}}
                            onClick={action.submit.set}
                            disabled={action.isGenerateModal.value}
                            text='Save'
                            variant="contained" 
                            size="medium"
                        />
                }
                
            </Box>
            }
            {
                action.image.value ? 

                <Box sx={{display: 'flex', justifyContent:'center', width: '100%',margin:'10px'}}>
                    <Box sx={{display: 'flex', justifyContent:'center', flexDirection: 'column', alignItems:'center'}}>
                        
                        
                        <Box sx={{position:'relative'}}>
                            <MyImage onClick={()=>action.openImage.set(true)} src={action.image.value} alt={`Image`} sx={{...style.cardAreaImage, cursor:'default', backgroundImage:'none'}} />
                            <PreviewIcon onClick={()=>action.openImage.set(true)} sx={{backgroundColor:'black', color: 'white', cursor:'pointer', height:'30px', position:'absolute', left:'10px', bottom:'10px'}} />
                            {/*<MyImage onClick={()=>saveImage(post.post.image)} src={downloadSvg} alt={`download icon`} sx={{cursor:'pointer', height:'30px', position:'absolute', left:'10px', bottom:'10px'}} />*/}
                        </Box>
                        {
                            action.file.value ? 
                            <Box sx={{marginTop:'5px'}}>
                                <ButtonDropImage image={action.image} setFile={action.file.set} />
                            </Box>
                            : null
                        }

                        <MyButton onClick={()=>console.log('teste')} text={action.file.value ? "Generate with AI2":"Generate other image"} variant="text" /> 

                    </Box>
                </Box>
                 : <></>
            }
            </Box>
            <MessageDialog open={action.open.value} handleClose={()=>action.open.set(false)} dateTime={action.dateTime.value} setDateTime={action.dateTime.set} handlerSchedulePublish={handlerSchedulePublish} />
            {
                (action.image) && action.openImage.value?
                    <MessageDialogImage open={action.openImage.value} handleClose={()=>action.openImage.set(false)} image={action.image.value} />
                : <></>
            }
        </Box>

    )
}

export default AdPostModal;