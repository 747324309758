import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import { AuthContext } from "../../../contexts/auth";
import MyLabel from "../../myLabel";
import MyButton from "../../myButton";
import MyInputText from "../../myInputText";

import CustonSelect from './custonSelect'
import SocialIcon from '../socialIcons'
import CHANNELS from "../channels"
import MyCheckbox from '../../myCheckbox';
import daleImage from "../../../assets/imgs/dale.png";
import CircularProgress from '@mui/material/CircularProgress';
import MySelect from "../../mySelect";
import MyInput from '../../myInput'
import PublicationsPanel from './publicationsPanel'
import { scrollbarTheme } from '../../../assets/customThemes/scrollbarTheme';
import useAdFromProductDescriptionState from "../../../hooks/ayrshare/useAdFromProductDescriptionState";

const style ={
    container:{
        minWidth: '700px',
        maxHeight:'85vh',
        overflowY: "auto", 
        '@media (max-width: 600px)': {
            width: 'auto',
            minWidth: 'auto',
        },
        ...scrollbarTheme
    },
    title:{
        fontSize: '12pt',
        fontWeight: '900'
    },
    descriptions:{
        fontSize: '12pt',
    },
    selectArea: {
        minWidth: '150px',
        '@media (max-width: 600px)': {
            width: 'auto',
        }
    },
    centerArea:{
        padding: '0px 40px',
        '@media (max-width: 600px)': {
            padding: '0px 10px',
        }
    },
    blueButton: {
        backgroundColor: '#376db4',
        ':hover': {
            backgroundColor: '#598FD6',
        }
    },
    publicationsArea: {
        maxHeight:'280px', 
        overflowY: "auto", 
        marginBottom:'10px',
        ...scrollbarTheme,
    },
    selectRowArea: {
        display: 'grid',
        gap: '15px',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center', 
        width: '100%', 
        margin:'15px 0',
        '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr 1fr',

        }
    }
}

const AdPostModal = ({setModal, profileSelected, socials, profiles, publish, submit, setModalOpen}) =>{
    const { integration } = useContext(AuthContext);
    
    let activeChannels = CHANNELS.filter(channel => channel.active).map(channel => channel.title)

    const [postFacebookPage, setPostFacebookPage] = useState(socials.value && socials.value?.includes('facebook') && socials.value?.includes('facebook') && activeChannels.includes('facebook'))
    const [postFacebookGroup, setPostFacebookGroup] = useState(socials.value && socials.value?.includes('fbg') && activeChannels.includes('fbg'))
    const [postInstagram, setPostInstagram] = useState(socials.value && socials.value?.includes('instagram') && activeChannels.includes('instagram'))
    const [postTwitter, setPostTwitter] = useState(socials.value && socials.value?.includes('twitter') && activeChannels.includes('twitter'))
    const [postLinkedin, setPostLinkedin] = useState(socials.value && socials.value?.includes('linkedin') && activeChannels.includes('linkedin'))
    const [postTiktok, setPostTiktok] = useState(socials.value && socials.value?.includes('tiktok') && activeChannels.includes('tiktok'))
    const [postYoutube, setPostYoutube] = useState(socials.value && socials.value?.includes('youtube') && activeChannels.includes('youtube'))

    
    const [publications, setPublications] = useState([
        {active: false, generating: false, social: 'facebook', text:'',  channel:{ value: postFacebookPage, set: setPostFacebookPage}},
        {active: false, generating: false, social: 'fbg', text:'',  channel:{ value: postFacebookGroup, set: setPostFacebookGroup}},
        {active: false, generating: false, social: 'instagram', text:'',  channel:{ value: postInstagram, set: setPostInstagram}},
        {active: false, generating: false, social: 'twitter', text:'', channel:{ value: postTwitter, set: setPostTwitter}},
        {active: false, generating: false, social: 'linkedin', text:'',  channel:{ value: postLinkedin, set: setPostLinkedin}},
        {active: false, generating: false, social: 'tiktok', text:'',  channel:{ value: postTiktok, set: setPostTiktok}},
        {active: false, generating: false, social: 'youtube', text:'',  channel:{ value: postYoutube, set: setPostYoutube}},
    ])

    const action = useAdFromProductDescriptionState(setPublications);



    const handlerGenerate = async () => {

        if (!postFacebookPage &&
            !postFacebookGroup &&
            !postInstagram &&
            !postTwitter &&
            !postLinkedin &&
            !postTiktok &&
            !postYoutube){
                toast.error(<FormattedMessage id='Select at least one publishing channel' />);
                return
        }

        let publi = publications.map((item)=>{
            
            return {...item, generating: item.channel.value}
        })

        action.submit.set(publi)

    }

    const handlerUpdate = async (channel) =>{
        let publi = publications.map((item)=>{
            return {...item, text: item.social === channel ? '': item.text, generating: item.social === channel ? true: item.generating}
        })
        setPublications(publi)
        action.submit.set(publi)
    }
        
    const handlerPublish = () => {
        const inte = {...integration.value}
        let publis = publications.filter(p=>p.active).map(p=>{ return {social: p.social, text: p.text}})
        inte.publications = publis;
        integration.set(inte);
        setModal(0)
    }

    const activePublication = (social, boolean) => {
        
        let publis = publications.map((publi)=> {
            if (publi.social === social && publi.text !==''){
                publi.active = boolean
            }
            return publi;
        })
        setPublications(publis)
        
    }

    useEffect(() => {
        activePublication('facebook', postFacebookPage)

    }, [postFacebookPage]);

    useEffect(() => {
        activePublication('fbg', postFacebookGroup)
    }, [postFacebookGroup]);

    useEffect(() => {
        activePublication('instagram', postInstagram)
    }, [postInstagram]);

    useEffect(() => {
        activePublication('twitter', postTwitter)
    }, [postTwitter]);

    useEffect(() => {
        activePublication('linkedin', postLinkedin)
    }, [postLinkedin]);

    
    return (
        <Box sx={style.container}>
            <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', margin:0, marginBottom:'20px'}}  >
                <MyLabel component='h3' text='Generate with AI' sx={style.title}  />
            </Box>
            <Box sx={{borderBottom: '1px solid #adb5bd', width:'100%',marginBottom:'20px'}}/>
            <Box sx={style.centerArea}>
            <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignItems: 'center'}} >
                <Box sx={{margin: 0, display: 'flex', height:'105px'}}>
                        <Box sx={style.selectArea}>
                            <MyLabel component='p' text='Select a brand' sx={style.descriptions}  />
                            <CustonSelect 
                                id='style'
                                label=""
                                options={profiles.value}
                                value={profileSelected.value}
                                onChange={(e)=>profileSelected.set(e.target.value)}
                                noTranslate={true} 
                            />
                        </Box>
                </Box>
                <Box sx={{ marginLeft:'20px', minWidth:'50px', height:'110px'}}>
                        <Box>
                            <MyLabel component='p' text='Select channels' sx={style.descriptions}  />    
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'row'}}>
                            {
                                    CHANNELS.filter(chanel=>chanel.active).map((channel, key)=> {
                                        if (socials.value && socials.value?.includes(channel.title)){

                                            let checked = postFacebookPage;
                                            let onChange = setPostFacebookPage;
                                            if (channel.title ==='fbg' ){
                                                checked = postFacebookGroup;
                                                onChange = setPostFacebookGroup; 
                                            }
                                            else if (channel.title ==='instagram' ){
                                                checked = postInstagram;
                                                onChange = setPostInstagram; 
                                            }
                                            else if (channel.title ==='twitter' ){
                                                checked = postTwitter;
                                                onChange = setPostTwitter; 
                                            }
                                            else if (channel.title ==='linkedin' ){
                                                checked = postLinkedin;
                                                onChange = setPostLinkedin; 
                                            }
                                            else if (channel.title ==='tiktok' ){
                                                checked = postTiktok;
                                                onChange = setPostTiktok; 
                                            }
                                            else if (channel.title ==='youtube' ){
                                                checked = postYoutube;
                                                onChange = setPostYoutube; 
                                            }
                                            return (
                                                <Box key={key} sx={{padding:0, margin:0}}>
                                                    <SocialIcon sx={{height:'24px', width:'24px'}} icon={channel.icon} linked={true}  />
                                                    <MyCheckbox
                                                        sx={{margin:0}}
                                                        size='small'
                                                        checked={checked}
                                                        label=" " 
                                                        value={checked}
                                                        onChange={(e) => onChange(!checked)}
                                                    />
                                                </Box>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    })
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={style.selectRowArea}>
                    <Box sx={{'@media (max-width: 600px)': {width:'100%'}}}>
                        <MySelect
                            id='content'
                            size="medium"
                            label='What type of content do you prefer?*'
                            options={["A Post", "An AD", "A comment"]}
                            value={action.ad.value}
                            onChange={(e)=>action.ad.set(e.target.value)}
                        />
                    </Box>
                    <MySelect
                            id='type'
                            size="medium"
                            label='Choose the subject:'
                            options={["----", "product", "service",
                                "artist", "company", "tv show",
                                "event"]}
                            value={action.type.value}
                            onChange={(e)=>action.type.set(e.target.value)}
                        />
                    <MySelect
                            id='target'
                            size="medium"
                            label='What is your target?'
                            options={["all", "professionals", "mass market",
                            "students", "parents",
                        ]}
                            value={action.target.value}
                            onChange={(e)=>action.target.set(e.target.value)}
                        />
                    <MySelect
                        size="medium"
                        id='sector'
                        label='What is the sector of your target?'
                        options={["all", "arts", "business",
                            "Charity & Public Services", "Communication & Marketing",
                            "construction","education", "engineering", "Fashion & Beauty",
                            "finance", "healthcare", "law",
                            "medicine",
                            "pets", "retail",
                            "science", "technology",
                            "Travel & Tourism",
                        ]}
                        value={action.sector.value}
                        onChange={(e)=>action.sector.set(e.target.value)}
                    />
                    <MySelect
                        size="medium"
                        id='language'
                        label='In what language do you want it?'
                        options={["english", "spanish", "portuguese", "italian", "french", "german", "dutch", "russian", "japanese", "chinese (simplified)", "korean"]}
                        value={action.language.value}
                        onChange={(e)=>action.language.set(e.target.value)}
                    />
                    <FormControl fullWidth>
                        <MyInput 
                            size="medium"
                            type='text'
                            id='product'
                            label='Product name or website'
                            required={false}
                            value={action.product.value}
                            onChange={(e) => action.product.set(e.target.value)}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <MyInputText
                        minRows={4}
                        type='text'
                        id="An ice-cream store that sells ice-creams and serve food."
                        label="Describe your Product/service:"
                        placeholder="An ice-cream store that sells ice-creams and serve food."
                        required={true}
                        value={action.sendText.value}
                        onChange={(e) =>
                            action.sendText.set(e.target.value)
                        }
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                {
                    action.submit.value ? 
                    <Box sx={{display: 'flex', flexDirection:'column',justifyContent:'center', alignItems: 'center', width: '100%',margin:'10px'}}>
                        <CircularProgress size={30} color="primary" /> 
                        <MyLabel noTranslate={true} sx={{color:'rgb(192, 114, 207)', fontSize:'10pt', marginTop:2, fontStyle:'italic'}} text={action.messageUser.value} />      
                    </Box>
                    : 
                    <MyButton 
                        disabled={action.submit.value}
                        onClick={handlerGenerate}
                        text='Generate It'
                        variant="contained" 
                        size="small"
                    />
                }
                </Box>
                <Box sx={style.publicationsArea}>
                    {
                        publications.filter(publication =>publication.active).map((publication, key) => <PublicationsPanel publication={publication} key={key} publications={publications} setPublications={setPublications} update={()=>handlerUpdate(publication.social)} />)
                    }
                </Box>
                <Box sx={{display: 'flex', flexDirection:'row',justifyContent:'space-around', alignItems: 'center', width: '100%',margin:'10px'}}>
                    <MyButton 
                        disabled={!(publications.filter(publication => publication.active && publication.text !=='').length > 0)}
                        sx={style.blueButton}
                        onClick={handlerPublish}
                        text='Add to post'
                        variant="contained" 
                        size="small"
                    />
                </Box>
            </Box>
        </Box>

    )
}

export default AdPostModal;