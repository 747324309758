
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../loading";
import MyModal from "../../myModal";
import { AuthContext } from "../../../contexts/auth";
import useDalleState from "../../../hooks/openAI/useDalleState";

import Box from '@mui/material/Box';
import MyLabel from "../../myLabel";
import MyInputText from "../../myInputText";
import MyButton from "../../myButton";
import Card from '@mui/material/Card';

import { style } from "./styles";
import useUserDetailState from "../../../hooks/useUserDetailState";
import TutorialModal from './tutorialModal'
import CloseIcon from '@mui/icons-material/Close';

import MyImage from "../../myImage";
import downloadImage from "../../../utils/downloadImage";
import hdShotPng from '../../../assets/imgs/dalle/new-images/1.jpg'
import portraitPng from '../../../assets/imgs/dalle/new-images/2.png'
import nftPng from '../../../assets/imgs/dalle/new-images/4.png'
import detailsPng from '../../../assets/imgs/dalle/new-images/5.jpg'
import videogamePng from '../../../assets/imgs/dalle/new-images/6.jpg'
import cyberpunkPng from '../../../assets/imgs/dalle/new-images/7.jpg'
import surrealPng from '../../../assets/imgs/dalle/new-images/8.jpg'
import cubistPng from '../../../assets/imgs/dalle/new-images/9.jpg'
import GPT4stylePng from '../../../assets/imgs/dalle/new-images/3.jpg'
import popArtPng from '../../../assets/imgs/dalle/new-images/10.jpg'
import ThreeDStylePng from '../../../assets/imgs/dalle/new-images/11.jpg'
import streetArtPng from '../../../assets/imgs/dalle/new-images/12.jpg'
import comicBookPng from '../../../assets/imgs/dalle/new-images/13.jpg'
import digitalArtPng from '../../../assets/imgs/dalle/new-images/14.jpg'
import abstractPng from '../../../assets/imgs/dalle/new-images/15.jpg'
import minimalistPng from '../../../assets/imgs/dalle/new-images/16.jpg'
import paperCraftsPng from '../../../assets/imgs/dalle/new-images/17.jpg'
import photoBlendPng from '../../../assets/imgs/dalle/new-images/18.jpg'
import glassMosaicPng from '../../../assets/imgs/dalle/new-images/19.jpg'
import woolPng from '../../../assets/imgs/dalle/new-images/20.png'
import gothicPng from '../../../assets/imgs/dalle/new-images/21.jpg'
import collagePng from '../../../assets/imgs/dalle/new-images/22.jpg'
import neoFuturePng from '../../../assets/imgs/dalle/new-images/23.jpg'
import cartoonPng from '../../../assets/imgs/dalle//new-images/24.jpg'
import iperRealisticPng from '../../../assets/imgs/dalle/iperRealistic.png'
import tagPng from '../../../assets/imgs/dalle/tag.png'
import downloadSvg from "../../../assets/imgs/DOWNLOAD.svg";

// tutorial images
import tutorialIcon from '../../../assets/imgs/tutorial-icon.svg'

// svg
import pageOnBackSvg from '../../../assets/imgs/dalle/page-on-back.svg'
import pageOffBackSvg from '../../../assets/imgs/dalle/page-off-back.svg'
import pageOnForwardSvg from '../../../assets/imgs/dalle/page-on-forward.svg'
import pageOffForwardSvg from '../../../assets/imgs/dalle/page-off-forward.svg'
import tagSvg from '../../../assets/imgs/dalle/tag.svg'
import selectSvg from '../../../assets/imgs/dalle/select.svg'
import selectEmptySvg from '../../../assets/imgs/dalle/select-empty.svg'


const RenderCreatOptions = ({ options, deviceType, imageScrolling, setImageScrolling, cardSelected, handleSelectStyle }) => {

    return (

        <Box sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            gap: deviceType === 1 ? '0' : '20px',
            alignItems: 'center',
            '@media (max-width: 600px)': {
                gridTemplateColumns: '1fr 1fr 1fr',
            }

        }}>
            <Box style={{ cursor: 'pointer' }} onClick={() => imageScrolling !== 0 && setImageScrolling(imageScrolling - deviceType)}>
                <MyImage src={imageScrolling === 0 ? pageOffBackSvg : pageOnBackSvg} />
            </Box>
            {
                options.slice(imageScrolling, imageScrolling + deviceType).map((option) => (
                    <Card
                        onClick={() => cardSelected === option.id ? handleSelectStyle(null) : handleSelectStyle(option)}
                        key={option.id} sx={
                            {
                                width: '200px',
                                height: '320px',
                                display: 'flex',
                                padding: '5px 10px',
                                flexDirection: 'column',
                                borderRadius: "12px",
                                border: cardSelected === option.id && '1px solid #721384',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.2)',


                                },
                                alignItems: 'center',

                                '@media (max-width: 600px)': {
                                    width: '200px',
                                    height: '320px',
                                }
                            }
                        }>
                        <MyImage src={cardSelected === option.id ? selectSvg : selectEmptySvg} sx={{ width: '15px', height: '15px', position: 'relative', left: '88px', cursor: 'pointer' }} />
                        {option.id === 25 && <MyImage src={tagPng} sx={{ width: '40px', height: '40px', position: 'relative', left:'-40%', top:'-5%', zIndex:1  }} />}
                        <MyImage src={option.img} sx={{ width: '160px', height: '160px', borderRadius: '12px', position:'relative', bottom: option.id === 25 && '12%' }} />
                        <Box height={'10%'}>
                            <MyImage src={tagSvg} sx={{ width: '85px', height: '85px', position: 'relative', bottom: option.id === 25 ? '80px' : '45px', right: '20px' }} />
                            <MyLabel sx={{ position: 'relative', bottom: option.id === 25 ? '140px' : '105px', right: '20px', color: 'white', fontSize: '0.8rem' }} component='p' text={option.tag} />

                        </Box>
                        <MyLabel component='p' text={option.description} sx={{ position:'relative', bottom:option.id === 25 && '12%', lineHeight: '1.3', fontFamily: 'italic', color: '#808080', fontSize: '0.9rem' }} />


                    </Card>
                ))
            }
            <Box style={{ cursor: 'pointer' }} onClick={() => (imageScrolling + deviceType !== options.length) && imageScrolling !== 24 && setImageScrolling(imageScrolling + deviceType)}>
                <MyImage src={(imageScrolling + deviceType === options.length) || imageScrolling === 24 ? pageOffForwardSvg : pageOnForwardSvg} />
            </Box>
        </Box>

    );
};

function Dalle({ updateHistoric }) {

    const { historic, integration } = useContext(AuthContext);
    let navigate = useNavigate();
    const action = useDalleState();
    useEffect(() => {
        if (historic.value) {
            action.sendText.set(historic.value.text);
            action.size.set(historic.value.size);
            action.style.set(historic.value.style);
            action.returnImages.set([]);
            historic.set(null)
        }

    }, [historic.value]);



    const [isOpen, setIsOpen] = useState(false);
    let title = "Select the type of the image you want to create";
    let createAction = action.size;

    const saveImage = (src) => { downloadImage(src) };
    const [imageScrolling, setImageScrolling] = useState(0)
    const [cardSelected, setCardSelected] = useState(null)
    const [noSpecificStyleSelected, setNoSpecificStyleSelected] = useState(false)
    const [tutorialStep, setTutorialStep] = useState(1)
    const { user } = useContext(AuthContext);
    const [tutorialShow, setTutorialShow] = useState(false)


    useEffect(() => {
        if(user.login_count === 1){
            const access_dalle_page = JSON.parse(localStorage.getItem('access_dalle_page'))
            if(!access_dalle_page){
                setTutorialShow(true)
                localStorage.setItem('access_dalle_page', true)
            }
        }
    }, [])
    
    const handlerPost = (src) => {
        const inte = { ...integration.value }
        inte.image = src;
        integration.set(inte);
        navigate('/posts')
    };
    const userState = useUserDetailState();

    const update = () => {

        if (!userState.get.value) {
            userState.get.set();
        }
    };

    useEffect(() => {
        update();
    }, []);



    let createOptions = [
        {
            id: 25,
            parameter: 'Hyper realistic',
            tag: 'Hyper realistic',
            img: iperRealisticPng,
            description: "“A plate of spaghetti and a glass of red wine”",
        },
        {
            id: 1,
            parameter: 'High-Quality Photography',
            tag: 'HD-Shot',
            img: hdShotPng, description: "“A stunning high-definition shot of a serene mountain lake at sunrise”"
        },
        {
            id: 2,
            parameter: '---',
            tag: 'Portrait',
            img: portraitPng,
            description: "“A girl with a cat”",
        },
        {
            id: 3,
            parameter: 'A GPT-4 style art of',
            tag: 'GPT-4 Style',
            img: GPT4stylePng,
            description: "“A whimsical wonderland scene showing different animals engaged in a literary debate under a fruit tree.“",
        },
        {
            id: 4,
            parameter: 'Pixel Art',
            tag: 'NFT',
            img: nftPng,
            description: "“A cool cat wearing neon sunglasses”",
        },


        //    block two 
        {
            id: 5,
            parameter: 'Macro 35mm photograph',
            tag: 'Details',
            img: detailsPng,
            description: "“A honeybee collecting nectar from a sunflower in full bloom”",
        },
        {
            id: 6,
            parameter: 'Game-style Art',
            tag: 'Video Game',
            img: videogamePng, description: "“A heroic knight confronting a fire-breathing dragon”"
        },
        {
            id: 7,
            parameter: 'Cyberpunk',
            tag: 'Cyberpunk',
            img: cyberpunkPng, description: "“High-tech robotic character in a dystopian cityscape”"
        },
        {
            id: 8,
            parameter: 'Surrealism',
            tag: 'Surreal',
            img: surrealPng,
            description: "“A playful elephant balancing on a tightrope between clouds”",
        },

        //    block three
        {
            id: 9,
            parameter: 'Cubism',
            tag: 'Cubist',
            img: cubistPng,
            description: "“A collage-style portrait with multi-colored, geometric planes”",
        },

        {
            id: 10,
            parameter: 'Pop Art',
            tag: 'Pop-Art',
            img: popArtPng,
            description: "“A vibrant portrait of a famous celebrity with comic book style dots”",
        },
        {
            id: 11,
            parameter: '3D Render',
            tag: '3D Style',
            img: ThreeDStylePng,
            description: "“A shiny red apple on a blue table”",
        },
        {
            id: 12,
            parameter: 'Graffiti Art',
            tag: 'Street Art',
            img: streetArtPng,
            description: "“A child reaching out for a heart-shaped balloon on a graffiti-filled wall”",
        },


        //    block four
        {
            id: 13,
            parameter: 'Comic Book Style',
            tag: 'Comic Book',
            img: comicBookPng,
            description: "“Retro-style detective in a suspense-filled crime scene”",
        },

        {
            id: 14,
            parameter: 'Digital Art',
            tag: 'Digital Art',
            img: digitalArtPng,
            description: "“A waterfall in a lush, neon forest”",
        },
        {
            id: 15,
            parameter: 'Abstract Art',
            tag: 'Abstract',
            img: abstractPng,
            description: "“Colorful shapes dancing in a sea of pastel tones”",
        },
        {
            id: 16,
            parameter: 'Minimalism',
            tag: 'Minimalist',
            img: minimalistPng,
            description: "“A solo floating white balloon against a vivid blue sky”",
        },



        //    block five
        {
            id: 17,
            parameter: 'Paper Crafts',
            tag: 'Paper Art',
            img: paperCraftsPng,
            description: "“A 3D origami crane flying against a pastel backdrop”",
        },

        {
            id: 18,
            parameter: 'Photomontage',
            tag: 'MixPhoto',
            img: photoBlendPng,
            description: "“Surreal photomontage of the four seasons converging in a landscape”",
        },
        {
            id: 19,
            parameter: 'Stained Glass',
            tag: 'Mosaic',
            img: glassMosaicPng,
            description: "“A colorful and intricate rose”",
        },
        {
            id: 20,
            parameter: 'Knitted out of the wool',
            tag: 'Wool',
            img: woolPng,
            description: "“A sushi roll”",
        },


        //    block six
        {
            id: 21,
            parameter: 'Gothic Art',
            tag: 'Gothic',
            img: gothicPng,
            description: "“A haunting gothic cathedral under a stormy sky”",
        },

        {
            id: 22,
            parameter: 'Collage Art',
            tag: 'Collage',
            img: collagePng,
            description: "“Retro pop-culture magazine cutouts forming a vibrant face”",
        },
        {
            id: 23,
            parameter: 'Futuristic Neon Lit',
            tag: 'NeoFuture',
            img: neoFuturePng,
            description: "“A city skyline reflecting in a tranquil river”",
        },
        {
            id: 24,
            parameter: 'Cartoon',
            tag: 'Cartoon',
            img: cartoonPng,
            description: "“Cheerful aliens picnicking on a sunny planet”",
        },



    ];

    let describes = [
        {
            title: "Describe the image you want to create",
            placeholder:
                "A group of friends in the 18th century taking a selfie with a smartphone",
            action: action.sendText,
        },
    ];

    let returnImages = {
        action: action.returnImages,
    };

    let imgs = [];

    if (returnImages.action.value.length > 0) {
        returnImages.action.value.forEach((item) => imgs.push(item));
    }

    const handleSelectStyle = (option) => {
        setNoSpecificStyleSelected(false)
        action.style.set(option?.parameter ?? '')
        setCardSelected(option?.id)
    }

    const [deviceType, setDeviceType] = useState(4);

    useEffect(() => {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                var UA = navigator.userAgent;
                hasTouchScreen =
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
        }
        if (hasTouchScreen) {
            setDeviceType(1);
        } else {
            setDeviceType(4);
        }
    }, []);

    const handlerMyImputClick = () => {

        if (!user.is_pro && !user.has_tested){
            navigate(window.location.pathname + '?try-pro=true')
        }else if (userState.freeImage.value <= 0){
            navigate("/credits");
        }
        
    }

    return (
        <Box sx={style.stack}>

            <Loading render={action.submit.value} />
            <Box sx={style.titleArea} >
                <MyLabel component='h1' text={title} sx={style.title} />
            </Box>

            <Box sx={{ textAlign: 'center', position: 'relative', }} >
                <RenderCreatOptions
                    deviceType={deviceType}
                    imageScrolling={imageScrolling}
                    cardSelected={cardSelected}
                    handleSelectStyle={handleSelectStyle}
                    setImageScrolling={setImageScrolling}
                    options={createOptions}
                    selected={createAction}
                />
            </Box>

            <Box sx={style.center} >
                <Box sx={{
                    marginTop: '20px',
                    width: deviceType === 1 ? '200px' : ' 700px',
                }}>
                    <Box display={'flex'} flexDirection={'row'} style={{ position: 'relative', right: '15px' }} >
                        <MyImage onClick={() => {

                            action.style.set('')
                            setCardSelected(null)
                            setNoSpecificStyleSelected(!noSpecificStyleSelected)
                        }} src={noSpecificStyleSelected ? selectSvg : selectEmptySvg} sx={{ width: '15px', height: '15px', cursor: 'pointer', position: 'relative', top: '4px', right: '5px' }} />
                        <MyLabel component={'p'} sx={{ fontWeight: 800, fontSize: '1rem' }} text={"No specific style"} />
                    </Box>

                    <MyLabel component={'p'} sx={{ fontSize: '0.9rem' }} text={"Open Canvas - Create with complete freedom and let your imagination run wild!"} />
                </Box>
            </Box>
            {tutorialShow && (
                <>
                    {tutorialStep === 1 &&
                        <Box sx={style.tutorialBox}>
                           
                            <CloseIcon onClick={() => {
                                setTutorialShow(false)
                                setTutorialStep(1)
                            }} sx={style.tutorial1CloseImage} />
                            <MyLabel component={'p'} text={'Choose the most suitable style'} sx={style.tutorial1Title} />
                            <Box height={'4.5rem'}>
                                <MyLabel component={'p'} sx={style.tutorial1Content} text={'Select one from the most famous styles! We have already trained the AI for these 24 styles, you just have to describe the image you want and the AI will generate it in the style you have chosen.'} />
                            </Box>
                            <Box sx={style.tutorial1Step}>{tutorialStep} of 4</Box>
                            <MyButton onClick={() => setTutorialStep(tutorialStep + 1)} variant="contained" sx={style.tutorial1NextButton} text={'Next'} />
                            <MyLabel component={'p'} text={'Previous'} sx={style.tutorial1Previous} />
                        </Box>
                    }

                    {tutorialStep === 2 &&
                        <Box sx={style.tutorial2Box} height={0}>
                            
                            <CloseIcon onClick={() => {
                                setTutorialShow(false)
                                setTutorialStep(1)
                            }} sx={style.tutorial1CloseImage} />
                            <MyLabel component={'p'} text={'Try the freestyle'} sx={style.tutorial1Title} />
                            <Box height={'4.5rem'}>
                                <MyLabel component={'p'} sx={style.tutorial1Content} text={`Can't find a style you like or would you like to try something different? Choose this option to test your imagination without any stylistic restrictions.`} />
                            </Box>
                            <Box sx={style.tutorial1Step}>{tutorialStep} of 4</Box>

                            <MyButton onClick={() => setTutorialStep(tutorialStep + 1)} variant="contained" sx={style.tutorial1NextButton} text={'Next'} />
                            <MyLabel onClick={() => setTutorialStep(tutorialStep - 1)} component={'p'} text={'Previous'} sx={style.tutorial1Previous} />

                        </Box>
                    }

                    {tutorialStep === 3 &&
                        <Box sx={style.tutorial3and4Box} height={0}>
                            
                            <CloseIcon onClick={() => {
                                setTutorialShow(false)
                                setTutorialStep(1)
                            }} sx={style.tutorial1CloseImage} />
                            <MyLabel component={'p'} text={'Write a concrete description'} sx={style.tutorial1Title} />
                            <Box height={'4.5rem'}>
                                <MyLabel component={'p'} sx={style.tutorial1Content} text={'1. Avoid abstract terms. For example, instead of "Marketing", "Finance", "Business" use objective words like "megaphone", "banknotes", "skyscraper", etc.'} />
                            </Box>
                            <Box sx={style.tutorial1Step}>{tutorialStep} of 4</Box>
                            <MyButton onClick={() => setTutorialStep(tutorialStep + 1)} variant="contained" sx={style.tutorial1NextButton} text={'Next'} />
                            <MyLabel onClick={() => setTutorialStep(tutorialStep - 1)} component={'p'} text={'Previous'} sx={style.tutorial1Previous} />
                        </Box>
                    }


                    {tutorialStep === 4 &&
                        <Box sx={style.tutorial3and4Box} height={0}>
                            
                            <CloseIcon onClick={() => {
                                setTutorialShow(false)
                                setTutorialStep(1)
                            }} sx={style.tutorial1CloseImage} />
                            <MyLabel component={'p'} text={'Write a concrete description'} sx={style.tutorial1Title} />
                            <Box height={'4.5rem'}>
                                <MyLabel component={'p'} sx={style.tutorial1Content} text={'2. Make a detailed description. The more details you add, also for the background, the more accurate the image will be, for example, "A white balloon flying in a bright blue sky"'} />
                            </Box>
                            <Box sx={style.tutorial1Step}>{tutorialStep} of 4</Box>
                            <MyButton onClick={() => {
                                setTutorialShow(false)
                                setTutorialStep(1)
                            }} variant="contained" sx={style.tutorial1NextButton} text={'Finish'} />
                            <MyLabel onClick={() => setTutorialStep(tutorialStep - 1)} component={'p'} text={'Previous'} sx={style.tutorial1Previous} />
                        </Box>
                    }
                </>
            )}

            {describes.map((describe, key) => {
                return (
                    <Box key={key} sx={{
                        marginTop: '20px',
                        width: '70%',
                        position: 'relative',
                        left: '15%',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <MyImage src={tutorialIcon} onClick={() => setTutorialShow(true)} sx={{ cursor: 'pointer', position: 'relative', bottom: '100px' }} />
                        <MyInputText
                            onClick={handlerMyImputClick}
                            sx={style.textInput}
                            type='text'
                            id={describe.placeholder}
                            label={describe.title}
                            placeholder={describe.placeholder}
                            required={true}
                            value={describe.action.value}
                            onChange={(e) => {
                                describe.action.set(e.target.value)
                            }
                            }
                        />
                    </Box>
                );
            })}

            <Box sx={style.buttonArea}>
                <MyButton
                    disabled={action.submit.value }
                    onClick={async () => {
                        action.submit.set(updateHistoric);
                    }}
                    text="Generate It"
                    variant="contained"
                    size="large"
                />
            </Box>
            <Box sx={style.center} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    margin: '10px',
                    padding: 0,
                }} >
                    {imgs.map((img, key) => {
                        
                        return (
                            <Box key={key} style={{cursor:'pointer'}} onClick={() => saveImage(img)}>
                                <Box>
                                    <MyImage src={downloadSvg} alt={`Image ${key}`} sx={{widht:'30px', height:'30px', position:'relative', left:'400px', bottom:'370px'}} />
                                    <MyImage src={`${img}`} alt={`Image ${key}`} sx={style.imageReturn} />
                                 </Box>
                            </Box>
                        )

                    })}
                </Box>
            </Box>


            <MyModal title="tutorial" isOpen={isOpen} setIsOpen={setIsOpen}>
                <TutorialModal />
            </MyModal>
        </Box>
    );
}

export default Dalle;
