import { Box, Divider, Tooltip, styled, tooltipClasses } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info'
import { FormattedMessage } from 'react-intl';
import { scrollbarTheme } from '../../assets/customThemes/scrollbarTheme';
import caricamentoSvg from '../../assets/imgs/caricamento.svg'
import MyLabel from "../../components/myLabel"
import MyInputText from '../../components/myInputText';
import useEditorialState from "../../hooks/ayrshare/planner/useEditorialState";
import MyCheckbox from "../../components/myCheckbox";
import MySelect from "../../components/mySelect";
import MyButton from "../../components/myButton";
import toast from "react-hot-toast";
import MyImage from "../../components/myImage";
import Loading from "../../components/loading";
import { useEffect, useState } from "react";
import CHANNELS from "../../components/ayrshare/channelsNew";
import SocialIcon from "../../components/ayrshare/socialIcons";
import CheckIcon from '@mui/icons-material/Check';
import plannerBackground from "../../assets/imgs/planner-background.png";


const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }));

const languageMapped = {
    0: 'english',
    1: 'spanish',
    2: 'portuguese',
    3: 'italian',
    4: 'german',
    5: 'french'
}

const Checked = () => {

    return (
        <Box sx={{
            backgroundColor:'#601c7e',
            width:"16px",
            height:"16px",
            borderRadius:'16px',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems: 'center',

        }}>
            <CheckIcon style={{color: 'white', fontSize:'11px'}} />
        </Box>
    )

}

const NotChecked = () => {

    return (
        <Box sx={{
            backgroundColor:'#ffffff',
            border:'solid 2px  #cac3c3',
            width:"16px",
            height:"16px",
            borderRadius:'16px',
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            alignItems: 'center',
            

        }}>
            <CheckIcon style={{color: 'white', fontSize:'11px'}} />
        </Box>
    )

}

const CheckImage = ({value, onClick, text}) => {

    return (
        <Box onClick={onClick} sx={{display:'flex', justifyContent:'center', textAlign:'center',flexDirection:'column', alignContent:'center',alignItems:'center',}}>
            <Box sx={{
                boxShadow: '0 0 2px 2px #BCBCBC', 
                marginBottom:'10px' ,
                borderRadius:'4px', 
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                width:"220px", 
                height:"80px", 
                backgroundColor: value ? '#601c7e': '#cac3c3',
                '@media (max-width: 600px)': {
                    padding: '20px',
                    width:"8rem", 
                }}}>
                <MyLabel sx={{color: value ? '#fff': '#000',}} component={'p'}  text={text} />
            </Box>
            
            {
                value ? <Checked /> : <NotChecked />
            }
            
        </Box>
    )
}

const Step3 = ({states}) => {

    return (
        <Box sx={{
            '@media (max-width: 600px)': {
                    padding:'10px'
                    
                }
        }}>
            <Loading render={states.profileUrl.value === ''} />
            <Box>
                <MyLabel component={'h1'} sx={{ ...styles.title, fontSize: '16pt'}} text={`Connect your social media`} />
                <MyLabel component={'p'} sx={styles.title2} text={`Click on the icons of the social networks where you wish to post and connect your accounts:`} />
            </Box>
            <Box sx={{
                marginTop: '3rem',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px',
                '@media (max-width: 600px)': {
                    marginTop: '1rem',
                    display: 'grid', 
                    gap: '10px',
                    gridTemplateColumns: '1fr 1fr',
                    
                }
            }}>
                {
                CHANNELS.map((channel, key) => {
                    let sx = { height: '60px', width: '60px', };

                    if (!channel.active) {
                    sx = { ...sx, opacity: '0.3' };
                    }
                    return (
                    <Box sx={{ 
                        margin: 0, 
                        position: 'relative', 
                        borderRadius: '10px',
                        padding: '30px 0px',
                        border:'1px solid #721384',
                        '@media (max-width: 600px)': {
                            width: '120px',
                        }
                        }} key={key} onClick={() => { 
                            states.socials.value?.filter(item => item === channel.icon).length === 0 && window.open(states.profileUrl.value, '_blank'); 
                        
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems:'center' }}>
                            <SocialIcon sx={{
                                ...sx,
                                width: '120px',
                                '@media (max-width: 600px)': {
                                width: '60px',
                                }
                            }} icon={channel.icon} linked={states.socials.value?.filter(item => item === channel.icon).length > 0} />
                            <Box>
                                <MyButton
                                sx={{
                                    backgroundColor: states.socials.value?.filter(item => item === channel.icon).length > 0 ? 'green' : 'grey',
                                    marginTop: '1rem',
                                    fontSize: '0.8rem',
                                    ':disabled': {
                                    backgroundColor: "green",
                                    color: 'white'
                                    }

                                }}
                                disabled={states.socials.value?.filter(item => item === channel.icon).length > 0}
                                text={states.socials.value?.filter(item => item === channel.icon).length > 0 ? 'Connect' : 'Connect'}
                                variant="contained"
                                size="small"
                                />
                            </Box>
                        </Box>
                        {states.socials.value?.filter(item => item === channel.icon).length > 0 && <Box sx={{ position: 'relative', left: '0.7rem', top: '0.2rem', width: '100px', }}>
                            <MyLabel onClick={() => {states.socials.value?.filter(item => item === channel.icon).length === 0 && window.open(states.profileUrl.value, '_blank');}}component='p' text='Disconnect' sx={{ cursor: 'pointer', fontWeight: 500, textDecoration: 'underline', fontSize: '0.8rem', }} />
                        </Box>
                        }
                        <Box sx={{ position: 'absolute', padding: '5px', width: '100px', display: 'none' }}>
                        <MyLabel component='p' text='update soon' sx={{ fontWeight: 500, fontStyle: 'italic' }} />
                        </Box>
                    </Box>
                    )
                })
                }
            </Box>
            <Box sx={{marginTop:'10px'}}>
                <Divider />
            </Box>
            <Box sx={{marginTop:'20px','@media (max-width: 600px)': {
                            marginTop: '0px', padding:'0px', margin: '0px', display:'flex'
                        }}}>
                <MyButton
                    sx={styles.button3}
                    disabled={false}
                    onClick={() => {
                    states.step.set(3)
                    }
                    }
                    text='Back'
                    variant="contained"
                    size="medium"
                />
                <MyButton
                    disabled={false}
                    onClick={() => { states.step.set(4)}}
                    text='Save and proceed'
                    variant="contained"
                    size="medium"
                />
                
            </Box>

            
            
        </Box>
                            
    )
}

const Step4 = ({states}) => {

    let checks = [
        {text: `One post a day for each connected social media`},
        {text:`Posts scheduled and published automatically`},
        {text:`Each month, the AI creates posts without having to do anything`}
    ]

    return (
        <>
            <MyLabel component={'h1'} sx={{...styles.title, fontSize: '16pt', marginTop:'20px', '@media (max-width: 600px)': {marginTop: '0px',} }} text={`Here's what you'll get now:`} />
            <Box sx={{marginTop:'30px', display:'flex', alignContent:'center', alignItems:'center', flexDirection: 'column', justifyContent:'center','@media (max-width: 600px)': {marginTop:'10px', flexDirection: 'column'}}}>
              
                {
                    checks.map((check, key)=>{
                        return (
                            <Box key={key} sx={{
                                width:'600px',
                                borderRadius:'6px',
                                border:'solid 2px  #ae8bbd',
                                backgroundColor:'#cdb7d6',
                                display:'flex',
                                flexDirection:'row',
                                marginBottom:"20px",
                                alignItems:'center',
                                textAlign:'center',
                                padding: '6px 15px',
                                '@media (max-width: 600px)': {padding: '6px', width:'98%'}
                                
                            }}>
                                <Box sx={{marginRight:'30px','@media (max-width: 600px)': {marginRight: '10px',}}}>
                                    <Checked />
                                </Box>
                                <Box sx={{ alignContent:'center', alignItems:'center', textAlign:'center',}}>
                                    <MyLabel sx={{'@media (max-width: 600px)': {fontSize:'11pt'}}} component={'p'} text={check.text} />
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>

            <Box sx={{display: 'flex', width: "100%",  justifyContent:'center' }}>
                <Box sx={{ maxWidth:'600px', display: 'flex', flexDirection: 'column', marginTop: '1rem',  width:'100%', '@media (max-width: 600px)': {marginTop: '10px',}}}>
                    <Box sx={{ width: "100%" }}>
                        <MyLabel component={'h2'} sx={{color:'#707070'}} text={`Choose the type of images for your posts`} />
                    </Box>
                    <Box sx={{marginTop:'20px', display:'flex', justifyContent:'space-between',  width:'100%'}}>
                        <CheckImage 
                            value={states.generateImage.value}
                            onClick={(e) => states.generateImage.set(!states.generateImage.value)}
                            text={`AI-generated images`}
                        />
                        <CheckImage 
                            value={!states.generateImage.value}
                            onClick={(e) => states.generateImage.set(!states.generateImage.value)}
                            text={`Upload my images`}
                        />
                    </Box>
                </Box>
            </Box>
            
            
            
            <Box sx={{marginTop:'20px'}}>
                <Divider />
            </Box> : <></>
            
            <Box sx={{display:"flex", justifyContent:'end', marginRight:'20px', '@media (max-width: 600px)': {marginRight: '0px',}}}>
            <MyButton
                sx={{
                marginTop: '1rem',
                backgroundColor: 'lightgrey',
                color: 'grey',
                marginRight: '0.8rem',

                '&:hover': {
                    backgroundColor: 'lightgrey',
                    opacity: '0.8'
                }

                }}
                disabled={false}
                onClick={() => states.step.set(3)}
                text='Back'
                variant="contained"
                size="medium"
            />
            <MyButton sx={{ marginTop: '1rem'}}
                disabled={false}
                onClick={() => {
                states.submit.set()
            }}
                text='Generate It'
                variant="contained"
                size="medium"
            />


            </Box>
            

          </>
    )
}

const Mobile = ({states}) => {

    const handlerSave = ()=>{
        if (states.step.value === 0){
            let nameValid = false
            let siteValid = false
            
            if (!states.name.value || states.name.value === ''){
                toast.error(<FormattedMessage id="Provide a valid name" />);
            }
            else{
                nameValid = true
            }

            if ( !states.noHasSite.value && (!states.site.value || states.site.value === '')){
                toast.error(<FormattedMessage id="Provide a valid site" />);
            }
            else{
                siteValid = true
            }
            if (nameValid && siteValid){
                states.step.set(states.step.value+1)
            }

        }
        else if (states.step.value === 1){
            let descriptionValid = false

            if (!states.description.value || states.description.value === ''){
                toast.error(<FormattedMessage id="Provide a valid description" />);
            }
            else{
                descriptionValid = true
            }
            if (descriptionValid){
                states.step.set(states.step.value+1)
            }
        }
        else if (states.step.value === 2){
            let nameValid = false
            let siteValid = false
            let descriptionValid = false
            
            if (!states.name.value || states.name.value === ''){
                toast.error(<FormattedMessage id="Provide a valid name" />);
                states.step.set(0)
            }
            else if ( !states.noHasSite.value && (!states.site.value || states.site.value === '')){
                states.step.set(0)
                toast.error(<FormattedMessage id="Provide a valid site" />);
            }
            else if (!states.description.value || states.description.value === ''){
                states.step.set(2)
                toast.error(<FormattedMessage id="Provide a valid description" />);
            }
            else{
                siteValid = true
                descriptionValid = true
                nameValid = true
            }
            if (nameValid && siteValid && descriptionValid){
                states.profilePost.set()
            }

            
        }
        
    }
    if (states.step.value < 3){
        return (
            <>
                <Box sx={{padding:'20px'}}>
                    <ArrowBackIcon onClick={() => { states.step.set(states.step.value === 0 ? 0 : states.step.value - 1)}} sx={{ float: 'left' }} />
                    <MyLabel 
                        component={'h1'} 
                        sx={{...styles.title, color: '#EE9051', marginRight: '1rem !important', fontSize: '16pt'}}
                        text={states.step.value === 2 ? 'Ci siamo quasi!' : `Step ${states.step.value + 1}/2`} 
                    /> 
                    <Box sx={{marginTop:'20px'}}>
                        {
                            states.step.value === 0 ?
                            <>
                                <Box sx={styles.inputArea}>
                                    <Box sx={{...styles.inputDescriptionArea, marginLeft: '2.5rem'}}>
                                        <MyLabel component={'p'} sx={styles.inputDescription} text={'What is the name of your business?'} />
                                        <LightTooltip title={<FormattedMessage id={'This name will be used in the creation of posts for your business.'} />}>
                                            <InfoIcon style={{ color: '#C8C8C8', cursor: 'pointer', }} />
                                        </LightTooltip>
                                    </Box>
                                    <MyLabel component={'p'} sx={{...styles.inputDescription, fontSize: '10pt',}} text={'Enter the name of your brand or business'} />
                                    <MyInputText sx={{ backgroundColor: 'white' }}
                                        minRows={2}
                                        type='text'
                                        id="Enter the name of your brand or business"
                                        required={true}
                                        value={states.name.value}
                                        onChange={(e) =>
                                            states.name.set(e.target.value)
                                        }
                                    />
                                </Box>
                                <Box  sx={styles.inputArea}>
                                    <MyLabel component={'p'} sx={styles.inputDescription} text={'Do you have a website? Enter it here'} />
                                    <MyInputText
                                        sx={{ backgroundColor: 'white' }}
                                        minRows={2}
                                        type='text'
                                        id="Do you have a website? Enter it here"
                                        required={false}
                                        value={states.site.value}
                                        onChange={(e) =>
                                        states.site.set(e.target.value)
                                        }
                                    />
                                </Box>
                                <Box sx={{
                                    ...styles.inputArea,
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'center'
                                }}>
                                    <Box sx={{ display: 'flex', }}>
                                    <Box sx={{ position: "relative", bottom: '0.6rem', left: '1.2rem' }}>
                                        <MyCheckbox
                                        size='small'
                                        label=" "
                                        checked={states.noHasSite.value}
                                        value={states.noHasSite.value}
                                        onChange={(e) => states.noHasSite.set(!states.noHasSite.value)}
                                        />
                                    </Box>
                                    <MyLabel component={'p'}
                                        sx={styles.inputDescription} text={"I don't have a website"} />
                                    </Box>
                                </Box>
                            </>
                            : <></>
                        }
                        {
                            states.step.value === 1 ?
                            <>
                                <Box >
                                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-evenly' }}>
                                        <MyLabel component={'h1'}
                                            sx={{
                                            margin: '0px',
                                            padding: '0px',
                                            fontSize: '12pt',
        
                                            marginBottom: '10px'
                                            }} text={"Describe your business in more detail; the more information you provide, the better the AI will be at creating customized content for you!"} />
                                        <LightTooltip title={<FormattedMessage id={'Tip: Have you already created a nice description of your business for a presentation, your website, etc.? Copy and paste it here!'} />}>
                                            <InfoIcon style={{ color: '#C8C8C8', cursor: 'pointer' }} />
                                        </LightTooltip>
                                    </Box>
                                    <MyInputText
                                        sx={{ backgroundColor: 'white'}}
                                        rows={10}
                                        type='text'
                                        placeholder={`AdMind is an Italian excellence in AI solutions for businesses and Software Houses. Our extensive experience in Generative Artificial Intelligence, enriched by our specialization in marketing and business support, makes us reliable partners for innovative AI-based projects. We collaborate with AI technology leaders such as OpenAI, Google, Amazon, and Microsoft. Our mission is to offer versatile AI solutions that, integrated with our expertise, bring about strategic software and applications, as well as tailor-made solutions for our clients. Solutions capable of growing and innovating any type of business.`}
                                        id="Descrivi meglio la tua attività"
                                        required={false}
                                        value={states.description.value}
                                        onChange={(e) => states.description.set(e.target.value)}
                                    />
                                </Box>
                            </>: <></>
                        }
                        {
                            states.step.value === 2 ?
                            <>
                                <Box sx={{
                                    ...styles.inputArea,
                                    marginTop: '1rem', 
                                    display: 'flex', 
                                    justifyContent: 'center',
                                }}>
                                    <Box>
                                        <MyLabel component={'p'}
                                            sx={{...styles.inputDescription}} text={'Which language do you want the content in?'} />
                                        <MySelect
                                            id='content'
                                            size="medium"
                                            sx={{ backgroundColor: 'white' }}
                                            options={Object.values(languageMapped)}
                                            required={true}
                                            value={states.language.value}
                                            onChange={(e) => states.language.set(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
    
    
                            : <></>
                        }
                    </Box>
                </Box>
                <Box >
                    <MyButton
                        sx={styles.button}
                        disabled={false}
                        onClick={handlerSave}
                        text='Save and proceed'
                        variant="contained"
                        size="medium"
                        />
                </Box>
            </>
        )
    }
    else if (states.step.value === 3){
        return <Step3 states={states} />
    }
    else if (states.step.value === 4){
        return <Step4 states={states} mobile={true} />
    }

}

const Desktop = ({states}) => {

    if (states.step.value < 3){
        return (
            <>
                <MyLabel component={'h1'} sx={styles.title} text={'Tell us more in detail about your business.'} /> 
                <Box sx={styles.line}>
                    <Box sx={styles.column}>
                        <Box sx={{...styles.inputArea, position:'relative'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <Box sx={{display:'flex', flexDirection:'column',  justifyContent:'center' }}>
                                    <Box sx={{textAlign: 'left', }}>
                                        <MyLabel component={'p'} sx={styles.inputDescription} text={'What is the name of your business?'} />
                                        <MyLabel component={'p'} sx={{...styles.inputDescription, fontSize: '10pt',}} text={'Enter the name of your brand or business'} />
                                    </Box>
                                </Box>
                                <Box sx={{position:'absolute', right:0}}>
                                    <LightTooltip title={<FormattedMessage id={'This name will be used in the creation of posts for your business.'} />}>
                                        <InfoIcon style={{ color: '#C8C8C8', cursor: 'pointer', }} />
                                    </LightTooltip>
                                </Box>
                            </Box>
                            <MyInputText sx={{ backgroundColor: 'white' }}
                                minRows={2}
                                type='text'
                                id="Enter the name of your brand or business"
                                required={true}
                                value={states.name.value}
                                onChange={(e) =>
                                    states.name.set(e.target.value)
                                }
                            />
                        </Box>
                        <Box  sx={styles.inputArea}>
                            <MyLabel component={'p'} sx={styles.inputDescription} text={'Do you have a website? Enter it here'} />
                            <MyInputText
                                disabled={states.noHasSite.value}
                                sx={{ backgroundColor: 'white' }}
                                minRows={2}
                                type='text'
                                id="Do you have a website? Enter it here"
                                required={false}
                                value={states.site.value}
                                onChange={(e) =>
                                states.site.set(e.target.value)
                                }
                            />
                        </Box>
                        <Box sx={{
                            ...styles.inputArea,
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'center'
                        }}>
                            <Box sx={{ display: 'flex', }}>
                            <Box sx={{ position: "relative", bottom: '0.6rem', left: '1.2rem' }}>
                                <MyCheckbox
                                size='small'
                                label=" "
                                checked={states.noHasSite.value}
                                value={states.noHasSite.value}
                                onChange={(e) => {
                                    if (states.site.value !==''){
                                        states.noHasSite.set(false)
                                    } else {
                                        states.noHasSite.set(!states.noHasSite.value)
                                    }

                                }}
                                />
                            </Box>
                            <MyLabel component={'p'}
                                sx={styles.inputDescription} text={"I don't have a website"} />
                            </Box>
                        </Box>
                        <Box sx={{
                            ...styles.inputArea,
                            marginTop: '1rem', 
                            display: 'flex', 
                            justifyContent: 'center',
                        }}>
                            <Box>
                                <MyLabel component={'p'}
                                    sx={{...styles.inputDescription}} text={'Which language do you want the content in?'} />
                                <MySelect
                                    id='content'
                                    size="medium"
                                    sx={{ backgroundColor: 'white' }}
                                    options={Object.values(languageMapped)}
                                    required={true}
                                    value={states.language.value}
                                    onChange={(e) => states.language.set(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={styles.column}>
                      <Box sx={{marginTop: '2rem', '@media (max-width: 600px)': {marginTop: '0.5rem'}}}>
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-evenly' }}>
                          <MyLabel component={'h1'}
                            sx={{
                              margin: '0px',
                              padding: '0px',
                              fontSize: '12pt',

                              marginBottom: '10px'
                            }} text={"Describe your business in more detail; the more information you provide, the better the AI will be at creating customized content for you!"} />
                          <LightTooltip title={<FormattedMessage id={'Tip: Have you already created a nice description of your business for a presentation, your website, etc.? Copy and paste it here!'} />}>

                            <InfoIcon style={{ color: '#C8C8C8', cursor: 'pointer' }} />

                          </LightTooltip>
                        </Box>
                        <Box sx={{maxHeight:'380px', overflowY: "auto", ...scrollbarTheme, backgroundColor:'red', width:'100%'}}>
                            <MyInputText
                            sx={{ backgroundColor: 'white' }}
                            minRows={10}
                            type='text'
                            placeholder={`AdMind is an Italian excellence in AI solutions for businesses and Software Houses. Our extensive experience in Generative Artificial Intelligence, enriched by our specialization in marketing and business support, makes us reliable partners for innovative AI-based projects. We collaborate with AI technology leaders such as OpenAI, Google, Amazon, and Microsoft. Our mission is to offer versatile AI solutions that, integrated with our expertise, bring about strategic software and applications, as well as tailor-made solutions for our clients. Solutions capable of growing and innovating any type of business.`}
                            id="Descrivi meglio la tua attività"
                            required={false}
                            value={states.description.value}
                            onChange={(e) => states.description.set(e.target.value)}
                            />
                        </Box>
                      </Box>
                    </Box>
                </Box>
                <Box sx={{marginTop:'20px'}}>
                    <Divider />
                </Box>
                <Box sx={{marginTop:'20px', display:'flex', justifyContent:'end'}}>
                    <MyButton
                        sx={styles.button}
                        disabled={false}
                        onClick={() => 
                            states.profilePost.set()
                        }
                        text='Save and proceed'
                        variant="contained"
                        size="medium"
                    />
                </Box>
            </>
        )
    }
    else if (states.step.value === 3){
        return <Step3 states={states} />
    }
    else if (states.step.value === 4){
        return <Step4 states={states} mobile={false} />
    }
    return <></>
}

const PlannerEditorial = () =>{

    const states = useEditorialState()
    
    let [attempt, setAttempt] = useState(0);
    const isMobile = window.innerWidth < 600;

    useEffect(()=>{
        states.profile.set()
    },[])

    useEffect(()=>{
        if (states.profile.value && states.profile.value?.id > 0){
            states.profileUrl.set()
        }
    },[states.profile.value])

    useEffect(() => {
        if (states.step.value === 3){
            setTimeout(()=>{setAttempt(attempt+1)}, 5000)
        }
      }, [states.step.value]);

      useEffect(()=>{
        if (attempt > 0){
            checkSocials()
        }
    },[attempt])
    
      const checkSocials = () => {
        if (states.step.value === 3 && states.profile.value && states.profile.value?.id > 0 
            && states.profileUrl.value && states.profileUrl.value !== '') {
            states.profileUrl.set()
            setTimeout(()=>{setAttempt(attempt+1)}, 2500)
        }
        else {
            setAttempt(0)
        }
      }

      if (states.profilePost.value){
        return (
            <Box sx={{
                backgroundImage:`url(${plannerBackground})`, 
                backgroundSize: 'cover',
                width:'100%', 
                display:'flex', 
                justifyContent:'center', 
                alignItems:'center',
                '@media (max-width: 600px)': {
                    alignItems:'normal',
                }
            }}>
                <Box sx={{...styles.box, height:"50vh", '@media (max-width: 600px)': {
                    marginTop:'40px',
                height:"50vh",
                }}}>
                    <Box sx={{display:'flex', height:'100%', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                        <Box sx={{display:'flex', textAlign:'center',justifyContent:'center', alignContent:'center', alignItems:'center', flexDirection:'column'}}>
                            <Box className='loader' sx={{ marginBottom: '0.5rem' }}> 
                                <MyImage src={caricamentoSvg} /> 
                            </Box>
                            <MyLabel
                            component={'h1'}
                            sx={{
                                margin: '0px',
                                padding: '0px',
                                fontSize: '14pt',
                                fontWeight: 600,
                                marginBottom: '10px',
                
                                marginRight: '1rem !important'
                            }}
                            text={`The AI is analyzing your responses...`} />
                        </Box>
                    </Box>
                </Box>
              </Box>
        )
    }
    
    return (
        <Box sx={{
            backgroundImage:`url(${plannerBackground})`, 
            backgroundSize: 'cover',
            width:'100%', 
            display:'flex', 
            justifyContent:'center', 
            alignItems:'center',
            '@media (max-width: 600px)': {
                alignItems:'normal',
            }
        }}>
            <Box sx={styles.content}>
                <Box sx={styles.box}>
                {
                    isMobile ?
                        <Mobile states={states} />
                    :
                        <Desktop states={states} />
                }
                </Box>
            </Box>
        </Box>
    )
}

const textBase = {
    margin: '0px',
    padding: '0px',
}

const buttonBase = {
    float: 'right', 
}

const styles = {
    box:{
        width:'90%',
        padding:'20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px 2px #BCBCBC',
        backgroundColor:'#ffffff',
        '@media (max-width: 600px)': {
            padding:`20px`,
            marginTop:'40px',
            height:"78vh",
        }
    },
    content:{
        display:'flex',
        justifyContent:'center',
        width:'100%',
        textAlign: 'center', 
        '@media (max-width: 600px)': {
            backgroundImage:`none`,
        }
    },
    content02:{
        '@media (max-width: 600px)': {
            padding: '2rem 1rem',
        }
    },
    content03:{
        '@media (max-width: 600px)': {
            padding: '3rem 0.5rem 3rem 0.5rem',
        }
    },
    container01:{
        textAlign: 'center', 
        paddingTop: '2rem', 
        paddingLeft: "1rem", 
        paddingRight: "1rem",
        
        
        '@media (max-width: 600px)': {
            width: '90vw',
            paddingLeft: "1rem",
            height: '82vh',
            padding: '1.5rem 1rem',
        }
    },
    container02:{

        backgroundColor: 'white',
        width: '100%', 
        height: '25vh', 
        textAlign: 'left', 
        paddingTop: '1.5rem', 
        padding: "2rem 1rem",
        '@media (max-width: 600px)': {
            width: '85vw',
            height: '80vh',
        }
    },
    container03:{
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'start', 
        marginLeft: '1rem',
        '@media (max-width: 600px)': {
          flexDirection: 'column',
          marginLeft: '0.5rem'
        }
    },
    title:{
        ...textBase,
        fontSize: '16pt',
        fontWeight: 600,
      },
    title2:{
        ...textBase,
        fontSize: '16pt',
        marginBottom: '1rem',
        '@media (max-width: 600px)': {
            marginBottom: 0,
            fontSize: '12pt',
        }},
    line:{ 
        display: 'flex', 
        flexDirection: 'row',
        margin: '0 10rem',
        '@media (max-width: 600px)': {
            margin: 0,

        }
        
    },
    column:{
        display: 'flex', 
        justifyContent:'right',
        flexDirection: 'column', 
        width: '50%', 
        
        '@media (max-width: 600px)': {
          width: '100%',

        }
      },
    column02:{
        display: 'flex', 
        flexDirection: 'column', 
        marginTop: '1rem'
    },
    inputArea: {
        width: '90%', 
        marginTop: '2rem',
        
        '@media (max-width: 600px)': {
            width: '100%',
            marginTop: '0.5rem'
        }
    },
    inputDescriptionArea:{ 
        display: 'flex', 
        flexDirection: "row", 
        justifyContent: 'space-evenly' 
    },
    inputDescription:{
        ...textBase,
        fontSize: '12pt',
    },
    inputCheck:{
        ...textBase,
        fontWeight: 600,
        fontSize: '14pt',
        marginBottom: '1.5rem',
        '@media (max-width: 600px)': {
            fontSize: '10pt'
        }
    },
    inputCheck02:{
        position: 'relative',
        fontWeight: 600,
        right: '1rem',
        top: '0.5rem',
        margin: '0px',
        padding: '0px',
    },
    textInfo:{
        marginTop: '1rem',
        padding: '0px',
        fontSize: '8pt',
        color: '#B1B1B1',
        fontWeight: 400,
        marginBottom: '10px',
    },
    textInfo02:{
        color: '#601C7E',
        margin: '0px',
        padding: '0px',
        fontSize: '14pt',
        fontWeight: 800,
    },
    button:{
        float: 'right', 
        '@media (max-width: 600px)': {
          float: 'none',
          marginTop: '1rem'
        }
    },
    button2:{
    ...buttonBase,
    '@media (max-width: 600px)': {
        float: 'none',
        position: 'static',
        marginTop: '1.5rem',
        '@media (max-width: 600px)': {
        marginTop: '3rem'
        }
    }
    },
    button3:{
        backgroundColor: 'lightgrey',
        color: 'grey',
        marginRight: '0.8rem',

        '&:hover': {
            backgroundColor: 'lightgrey',
            opacity: '0.8'
        },
      
    },
}


export default PlannerEditorial